html {
  margin: 0;
  padding: 0;
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  overflow-x: hidden !important;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  width: 100%;
  padding: 11px 15px 11px 0;
}

.btn-primary {
  background-color: #00d9b2;
  border-color: #00d9b2;
}

.btn-primary:hover {
  color: #fff;
  background-color: #007b65;
  border-color: #007b65;
}

.btn-primary:focus {
  background-color: #007b65 !important;
  border-color: #007b65 !important;
}

.btn-primary:active {
  background-color: #007b65 !important;
  border-color: #007b65 !important;
}

.fa-coins {
  color: rgb(224, 124, 10);
  text-decoration: none !important;
}

* {
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 400;
  line-height: 1.5;
}