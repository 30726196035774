.mobile-navbar{
    display:grid;
    grid-gap:10px 10px;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 300px;
    position: fixed;
    z-index: 2002;
    padding-top: 20px;
    background: black;
    height: calc(100vh);
    top:0px;
    -webkit-transform: translateX(110%);
    -ms-transform: translateX(110%);
    transform: translateX(110%);
    -webkit-box-shadow: -10px 0 20px -10px rgb(0 0 0 / 10%);
    box-shadow: -10px 0 20px -10px rgb(0 0 0 / 10%);
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out;

}
.fa-bars{
    z-index:2000;
}
.fa-bars, .fa-times{
    
    color: white;
}
.fa-bars:hover, .fa-times:hover {
    color: #00d9b2 !important;
}
.fa-bars-scrolled{
    color:black;
}
.navbar-button, .navbar-close-button{
    border: none;
    background-color: Transparent;
    z-index:2001;
    transition:1s;
}
.navbar-close-button{
    
    right:0;
    
    width:40px;
    height:40px;
}
.cool-link
{
    color:white;
    height:50px;

}
.cool-link:hover
{
    -webkit-transition: .3s all ease-in-out;
    -o-transition: .3s all ease-in-out;
    transition: .3s all ease-in-out;
    color: #00d9b2 !important;
}
.mobile-menu-container{
    width:70%;
    margin-left:10%;
}

.navbar-menu-text {
    color:white;
    padding-left:2px;
}

.navbar-menu-text-scrolled {
    color:black !important;
    padding-left:2px;
}
