.link {
  cursor: pointer;
  color: black;
  transition: color 1s;
}

.link:hover {
  color: #00d9b2;
  transition: color 1s;
}

.link:hover span {
  color: #00d9b2;
  transition: color 1s;
}

.auth-button {
  color: white;
  border: none;
  border-radius: 30px;
  padding: 8px 20px;
  background-color: #00d9b2;
  width: 100%;
  font-size: 14px;
}

.auth-button:hover {
  background-color: #2eaf98;
  transition: background-color 0.5s;
}

.auth-button:disabled {
  background-color: #00d9b2;
  transition: background-color 0.5s;
}

.auth-input:enabled:focus {
  border-color: #00d9b2 !important;
  box-shadow: inset 0 0 0 1px #00d9b2, inset 0 0 0 1px #00d9b2,
    inset 0 0 0 1px #00d9b2, inset 0 0 0 1px #00d9b2;
}

.auth-input-error {
  border-color: red !important;
  box-shadow: inset 0 0 0 1px #ff0000, inset 0 0 0 1px #ff0000,
    inset 0 0 0 1px #ff0000, inset 0 0 0 1px #ff0000;
}

.auth-checkbox div {
  background: #00d9b2 !important;
  border-color: #00d9b2 !important;
}

.main-color {
  color: #00d9b2;
}

@media (max-width:400px) {
  .terms-gdpr{
    font-size:10px;
  }
}