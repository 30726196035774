.icon {
  color: white;
  transition: all 0.5s;
}

.icon:hover {
  color: #00d9b2 !important;
  transition: all 0.5s;
}

.icon-scrolled {
  color: black !important;
}

.navbar-link {
  padding: 24px;
  font-weight: 300;
  font-size: 1.1rem;
  line-height: 1.7;
  text-decoration: none;
  border: none;
  color: white !important;
  background-color: transparent;
  border-bottom: rgba(0, 217, 178, 0) 0 solid;
  transition: all 0.5s;
}

.navbar-link:hover {
  color: rgba(0, 217, 178, 1) !important;
  transition: all 0.5s;
}

.navbar-link-scrolled {
  color: black !important;
  transition: all 0.5s;
}

.site-navbar {
  position: fixed;
  z-index: 10;
  width: 100%;
  padding-left: 1rem;
  transition: all 0.5s;
}

@media (max-width:1265px) {
  .site-navbar {
    padding: 0.8rem;
  }
}

.site-navbar-scrolled {
  box-shadow: 0px 2px 15px grey;
  background-color: white;
}

.site-navbar-profile {
  background-color: white;
}

.row-container {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}